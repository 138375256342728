import { FormInput } from 'shared-ui/lib/customs/form/FormInput';
import * as Yup from 'yup';

const email: FormInput = {
  type: 'text',
  initValue: '',
  validation: Yup.string().email().required(),
  props: {
    name: 'email',
    label: 'User:email',
    type: 'email',
    autoComplete: 'email',
  },
};

const name: FormInput = {
  type: 'text',
  initValue: '',
  validation: Yup.string().required(),
  props: {
    name: 'name',
    label: 'User:name',
    type: 'text',
    autoComplete: 'name',
  },
};

const partnerName: FormInput = {
  type: 'text',
  initValue: '',
  validation: Yup.string().required(),
  props: {
    name: 'partnerName',
    label: 'User:partnerName',
    type: 'text',
  },
};

const password: FormInput = {
  type: 'text',
  initValue: '',
  validation: Yup.string().min(8).required(),
  props: {
    name: 'password',
    label: 'User:password',
    type: 'password',
    autoComplete: 'current-password',
  },
};

export const userInputs = {
  email,
  name,
  password,
  partnerName,
};
