import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: any; output: any; }
};

export type AcceptInvitationDto = {
  password: Scalars['String']['input'];
  token: Scalars['String']['input'];
};

export type AdminBusinessType = {
  __typename?: 'AdminBusinessType';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  stripeCustomerId?: Maybe<Scalars['String']['output']>;
};

export type AdminOptionsDto = {
  archiveUser: Scalars['String']['input'];
  stripePriceId: Scalars['String']['input'];
};

export type AdminOptionsType = {
  __typename?: 'AdminOptionsType';
  archiveUser: Scalars['String']['output'];
  id: Scalars['String']['output'];
  stripePriceId: Scalars['String']['output'];
};

export type AdminPartnerType = {
  __typename?: 'AdminPartnerType';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type AdminUpdateUserDto = {
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type AdminUpdateVehicleDto = {
  isArchived: Scalars['Boolean']['input'];
  token: Scalars['String']['input'];
};

export type AdminUserType = {
  __typename?: 'AdminUserType';
  business?: Maybe<AdminBusinessType>;
  email: Scalars['String']['output'];
  id: Scalars['String']['output'];
  isBusinessAccountOwner: Scalars['Boolean']['output'];
  isEmployee: Scalars['Boolean']['output'];
  isPartnerAccountOwner: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  partner?: Maybe<AdminPartnerType>;
  stripeCustomerId?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
};

export type AdminVehicleList = {
  __typename?: 'AdminVehicleList';
  list: Array<AdminVehicleType>;
  totalCount: Scalars['Int']['output'];
};

export type AdminVehicleSort = {
  createdAt?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['String']['input']>;
};

export type AdminVehicleType = {
  __typename?: 'AdminVehicleType';
  VIN?: Maybe<Scalars['String']['output']>;
  brand?: Maybe<Scalars['String']['output']>;
  business?: Maybe<AdminBusinessType>;
  id: Scalars['String']['output'];
  isArchived: Scalars['Boolean']['output'];
  model?: Maybe<Scalars['String']['output']>;
  modelYear?: Maybe<Scalars['Float']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  registerNumber?: Maybe<Scalars['String']['output']>;
  token?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
  user?: Maybe<AdminUserType>;
};

export type AdminVehicleWhere = {
  VIN?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  registerNumber?: InputMaybe<Scalars['String']['input']>;
};

export type AuthResponse = {
  __typename?: 'AuthResponse';
  token: Scalars['String']['output'];
  user: UserType;
};

export type AuthStatusResponse = {
  __typename?: 'AuthStatusResponse';
  status: Scalars['String']['output'];
};

export type BusinessType = {
  __typename?: 'BusinessType';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  photo?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type ChangeUserInformationDto = {
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type CompleteReminderDto = {
  mileage?: InputMaybe<Scalars['Int']['input']>;
  reminderId: Scalars['String']['input'];
  runningHours?: InputMaybe<Scalars['Int']['input']>;
  serviceEvents: Array<UpdateServiceEventDto>;
  vehicleId: Scalars['String']['input'];
};

export type CompleteReminderResponse = {
  __typename?: 'CompleteReminderResponse';
  count: Scalars['Int']['output'];
  ids: Array<Scalars['String']['output']>;
};

export type ConfirmDto = {
  confirmToken: Scalars['String']['input'];
  type: Scalars['String']['input'];
};

export type CreateFaultDto = {
  description: Scalars['String']['input'];
  isDone?: InputMaybe<Scalars['Boolean']['input']>;
  photoId?: InputMaybe<Scalars['String']['input']>;
  vehicleId: Scalars['String']['input'];
};

export type CreateReminderDto = {
  date: Scalars['DateTime']['input'];
  faultId?: InputMaybe<Scalars['String']['input']>;
  isNotificationable?: InputMaybe<Scalars['Boolean']['input']>;
  notificationDaysBefore?: InputMaybe<Scalars['Int']['input']>;
  title: Scalars['String']['input'];
  vehicleId: Scalars['String']['input'];
};

export type CreateServiceEventDto = {
  description?: InputMaybe<Scalars['String']['input']>;
  mileage?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  runningHours?: InputMaybe<Scalars['Int']['input']>;
  serviceDate: Scalars['DateTime']['input'];
  type: Scalars['String']['input'];
  vehicleId: Scalars['String']['input'];
};

export type CreateVehicleDto = {
  VIN: Scalars['String']['input'];
  brand?: InputMaybe<Scalars['String']['input']>;
  commissioning?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  mileage?: InputMaybe<Scalars['Int']['input']>;
  model?: InputMaybe<Scalars['String']['input']>;
  modelYear?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  photo?: InputMaybe<Scalars['String']['input']>;
  registerNumber?: InputMaybe<Scalars['String']['input']>;
  runningHours?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  warranty?: InputMaybe<Scalars['DateTime']['input']>;
};

export type FaultList = {
  __typename?: 'FaultList';
  list: Array<FaultType>;
  totalCount: Scalars['Int']['output'];
};

export type FaultSort = {
  createdAt?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['String']['input']>;
};

export type FaultType = {
  __typename?: 'FaultType';
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  id: Scalars['String']['output'];
  isDone: Scalars['Boolean']['output'];
  isRead: Scalars['Boolean']['output'];
  photo?: Maybe<Scalars['String']['output']>;
  user: UserType;
  vehicle: VehicleType;
};

export type FaultWhere = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isDone?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type FeedbackStatusType = {
  __typename?: 'FeedbackStatusType';
  status: Scalars['String']['output'];
};

export type ForgotPasswordDto = {
  email: Scalars['String']['input'];
};

export type ForgotPasswordResponse = {
  __typename?: 'ForgotPasswordResponse';
  status: Scalars['String']['output'];
};

export type InviteBusinessUserDto = {
  email: Scalars['String']['input'];
  isEmployee: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
};

export type InviteUserDto = {
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type LoginDto = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type Mutation = {
  __typename?: 'Mutation';
  /** [Admin] Delete user */
  aDeleteUser: AdminUserType;
  /** [Admin] Update user */
  aUpdateUser: AdminUserType;
  /** [Admin] Update vehicle */
  aUpdateVehicle: AdminVehicleType;
  /** Complete user invitation */
  acceptInvitationToken: Scalars['Boolean']['output'];
  /** Update user information */
  changeUserInformation: UserType;
  /** Complete reminder */
  completeReminder: CompleteReminderResponse;
  /** Confirm user email */
  confirm: AuthStatusResponse;
  /** Create a new fault */
  createFault: FaultType;
  createReminder: ReminderType;
  /** Create service event */
  createServiceEvent: ServiceEventType;
  /** Create a transfer token for the vehicle */
  createTransferToken: Scalars['String']['output'];
  /** Create a vehicle */
  createVehicle: VehicleType;
  /** Create a voucher */
  createVoucher: VoucherType;
  /** Delete the fault */
  deleteFault: FaultType;
  deleteReminder: ReminderType;
  /** Delete service event */
  deleteServiceEvent: ServiceEventType;
  /** Delete user */
  deleteUser: UserType;
  /** Delete a vehicle */
  deleteVehicle: VehicleType;
  /** Send forgot password link email to user */
  forgotPasswordConsumer: ForgotPasswordResponse;
  /** Send forgot password link email to user */
  forgotPasswordEmployee: ForgotPasswordResponse;
  /** Send forgot password link email to user */
  forgotPasswordPartner: ForgotPasswordResponse;
  /** Invite a user to the business */
  inviteBusinessUser: UserType;
  /** Invite partner user */
  invitePartnerUser: UserType;
  /** Login a consumer user */
  loginClient: AuthResponse;
  /** Login a business user */
  loginEmployee: AuthResponse;
  /** Login a partner user */
  loginPartner: AuthResponse;
  /** Mark the fault as read */
  markAsRead: Scalars['Boolean']['output'];
  /** Register a new business user account */
  registerBusinessUser: AuthResponse;
  /** Register a new consumer user account */
  registerConsumerUser: AuthResponse;
  /** Register a new partner user account */
  registerPartnerUser: AuthResponse;
  /** Account deletion request */
  requestAccountDelete: Scalars['Boolean']['output'];
  /** Resend confirmation email */
  resendConfirmation: AuthStatusResponse;
  /** Reset password with token */
  resetPassword: ResetPasswordResponse;
  /** Send feedback to the team */
  sendFeedback: FeedbackStatusType;
  /** Transfer the vehicle to the user */
  transferVehicle: TransferVehicleType;
  /** [Admin] Transfer vehicle to business account */
  transferVehicleToBusiness: AdminVehicleType;
  /** [Admin] Transfer vehicle to user account */
  transferVehicleToConsumer: AdminVehicleType;
  /** Update admin options */
  updateAdminOptions: AdminOptionsType;
  /** Update the business details of the current user */
  updateBusiness: BusinessType;
  /** Update the fault details */
  updateFault: FaultType;
  /** Mark the fault as done */
  updateFaultAsDone: FaultType;
  /** Update partner details by user */
  updatePartner: PartnerType;
  updateReminder: ReminderType;
  /** Update service event */
  updateServiceEvent: ServiceEventType;
  /** Update a vehicle */
  updateVehicle: VehicleType;
  /** Update a voucher */
  updateVoucher: VoucherType;
  /** Use a voucher */
  useVoucher: VoucherLogType;
  /** Verify if the invitation token is valid */
  verifyAcceptInvitationToken: Scalars['Boolean']['output'];
};


export type MutationADeleteUserArgs = {
  id: Scalars['String']['input'];
};


export type MutationAUpdateUserArgs = {
  body: AdminUpdateUserDto;
  id: Scalars['String']['input'];
};


export type MutationAUpdateVehicleArgs = {
  body: AdminUpdateVehicleDto;
  id: Scalars['String']['input'];
};


export type MutationAcceptInvitationTokenArgs = {
  body: AcceptInvitationDto;
};


export type MutationChangeUserInformationArgs = {
  body: ChangeUserInformationDto;
};


export type MutationCompleteReminderArgs = {
  body: CompleteReminderDto;
};


export type MutationConfirmArgs = {
  body: ConfirmDto;
};


export type MutationCreateFaultArgs = {
  body: CreateFaultDto;
};


export type MutationCreateReminderArgs = {
  body: CreateReminderDto;
};


export type MutationCreateServiceEventArgs = {
  body: CreateServiceEventDto;
};


export type MutationCreateTransferTokenArgs = {
  vehicleId: Scalars['String']['input'];
};


export type MutationCreateVehicleArgs = {
  body: CreateVehicleDto;
};


export type MutationCreateVoucherArgs = {
  body: VoucherDto;
};


export type MutationDeleteFaultArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteReminderArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteServiceEventArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteUserArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteVehicleArgs = {
  id: Scalars['String']['input'];
};


export type MutationForgotPasswordConsumerArgs = {
  body: ForgotPasswordDto;
};


export type MutationForgotPasswordEmployeeArgs = {
  body: ForgotPasswordDto;
};


export type MutationForgotPasswordPartnerArgs = {
  body: ForgotPasswordDto;
};


export type MutationInviteBusinessUserArgs = {
  body: InviteBusinessUserDto;
};


export type MutationInvitePartnerUserArgs = {
  body: InviteUserDto;
};


export type MutationLoginClientArgs = {
  body: LoginDto;
};


export type MutationLoginEmployeeArgs = {
  body: LoginDto;
};


export type MutationLoginPartnerArgs = {
  body: LoginDto;
};


export type MutationMarkAsReadArgs = {
  id: Scalars['String']['input'];
};


export type MutationRegisterBusinessUserArgs = {
  body: RegisterBusinessUserDto;
};


export type MutationRegisterConsumerUserArgs = {
  body: RegisterConsumerUserDto;
};


export type MutationRegisterPartnerUserArgs = {
  body: RegisterPartnerUserDto;
};


export type MutationResetPasswordArgs = {
  body: ResetPasswordDto;
};


export type MutationSendFeedbackArgs = {
  message: Scalars['String']['input'];
};


export type MutationTransferVehicleArgs = {
  token: Scalars['String']['input'];
};


export type MutationTransferVehicleToBusinessArgs = {
  businessId: Scalars['String']['input'];
  id: Scalars['String']['input'];
};


export type MutationTransferVehicleToConsumerArgs = {
  id: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};


export type MutationUpdateAdminOptionsArgs = {
  body: AdminOptionsDto;
};


export type MutationUpdateBusinessArgs = {
  body: UpdateBusinessDto;
};


export type MutationUpdateFaultArgs = {
  body: UpdateFaultDto;
  id: Scalars['String']['input'];
};


export type MutationUpdateFaultAsDoneArgs = {
  id: Scalars['String']['input'];
};


export type MutationUpdatePartnerArgs = {
  body: UpdatePartnerDto;
};


export type MutationUpdateReminderArgs = {
  body: UpdateReminderDto;
  id: Scalars['String']['input'];
};


export type MutationUpdateServiceEventArgs = {
  body: UpdateServiceEventDto;
  id: Scalars['String']['input'];
};


export type MutationUpdateVehicleArgs = {
  body: UpdateVehicleDto;
  id: Scalars['String']['input'];
};


export type MutationUpdateVoucherArgs = {
  body: VoucherDto;
  id: Scalars['String']['input'];
};


export type MutationUseVoucherArgs = {
  vehicleId: Scalars['String']['input'];
  voucher: Scalars['String']['input'];
};


export type MutationVerifyAcceptInvitationTokenArgs = {
  body: VerifyAcceptInvitationDto;
};

export type PartnerType = {
  __typename?: 'PartnerType';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  photo?: Maybe<Scalars['String']['output']>;
};

export type Query = {
  __typename?: 'Query';
  /** [Admin] Get business */
  aBusiness: AdminBusinessType;
  /** [Admin] Get user */
  aUser: AdminUserType;
  /** [Admin] Get users */
  aUsers: UserList;
  /** [Admin] Get a vehicle */
  aVehicle: AdminVehicleType;
  /** [Admin] Get vehicles */
  aVehicles: AdminVehicleList;
  /** Get admin options */
  adminOptions: AdminOptionsType;
  /** Get the business details of the current user */
  business: BusinessType;
  /** Create a new checkout session */
  createCheckoutSession: StripeCheckoutType;
  /** [Admin] Export users as CSV */
  exportCSV: Scalars['String']['output'];
  /** Get the fault details */
  fault: FaultType;
  /** Get the list of faults */
  faults: FaultList;
  /** Get partner details by user */
  partner: PartnerType;
  reminder: ReminderType;
  reminders: ReminderList;
  /** [Admin] Request admin login token */
  requestAdminToken: AuthStatusResponse;
  /** Search a vehicle by token */
  searchVehicle: VehicleType;
  /** Get service event */
  serviceEvent: ServiceEventType;
  /** Get all users */
  users: UserList;
  /** [Admin] Validate admin token */
  validateAdminToken: AuthStatusResponse;
  /** Validate reset password token */
  validateResetToken: ValidateResetTokenResponse;
  /** Validate the transfer token */
  validateTransferToken: ValidateTransferTokenType;
  /** Get a vehicle */
  vehicle: VehicleType;
  /** Get a list of vehicles */
  vehicles: VehicleList;
  /** Get a voucher */
  voucher: VoucherType;
  /** Get a list of vouchers */
  vouchers: VoucherList;
  /** Get user information */
  whoAmI: UserType;
};


export type QueryABusinessArgs = {
  id: Scalars['String']['input'];
};


export type QueryAUserArgs = {
  id: Scalars['String']['input'];
};


export type QueryAUsersArgs = {
  orderBy?: InputMaybe<Array<UserSort>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserWhere>;
};


export type QueryAVehicleArgs = {
  id: Scalars['String']['input'];
};


export type QueryAVehiclesArgs = {
  orderBy?: InputMaybe<Array<AdminVehicleSort>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<AdminVehicleWhere>;
};


export type QueryCreateCheckoutSessionArgs = {
  vehicleId: Scalars['String']['input'];
};


export type QueryFaultArgs = {
  id: Scalars['String']['input'];
};


export type QueryFaultsArgs = {
  orderBy?: InputMaybe<Array<FaultSort>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<FaultWhere>;
};


export type QueryReminderArgs = {
  id: Scalars['String']['input'];
};


export type QueryRemindersArgs = {
  orderBy?: InputMaybe<Array<ReminderSort>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ReminderWhere>;
};


export type QuerySearchVehicleArgs = {
  token: Scalars['String']['input'];
};


export type QueryServiceEventArgs = {
  id: Scalars['String']['input'];
};


export type QueryValidateResetTokenArgs = {
  body: ValidateResetTokenDto;
};


export type QueryValidateTransferTokenArgs = {
  token: Scalars['String']['input'];
};


export type QueryVehicleArgs = {
  id: Scalars['String']['input'];
};


export type QueryVehiclesArgs = {
  orderBy?: InputMaybe<Array<VehicleSort>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<VehicleWhere>;
};


export type QueryVoucherArgs = {
  id: Scalars['String']['input'];
};


export type QueryVouchersArgs = {
  orderBy?: InputMaybe<Array<VoucherSort>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<VoucherWhere>;
};

export type RegisterBusinessUserDto = {
  businessName: Scalars['String']['input'];
  email: Scalars['String']['input'];
  isAccepted: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type RegisterConsumerUserDto = {
  email: Scalars['String']['input'];
  isAccepted: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type RegisterPartnerUserDto = {
  email: Scalars['String']['input'];
  isAccepted: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  partnerName: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type ReminderList = {
  __typename?: 'ReminderList';
  list: Array<ReminderType>;
  totalCount: Scalars['Int']['output'];
};

export type ReminderSort = {
  createdAt?: InputMaybe<Scalars['String']['input']>;
  date?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['String']['input']>;
};

export type ReminderType = {
  __typename?: 'ReminderType';
  createdAt: Scalars['DateTime']['output'];
  date: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  isNotificationable?: Maybe<Scalars['Boolean']['output']>;
  isPartnerLocked: Scalars['Boolean']['output'];
  notificationDaysBefore?: Maybe<Scalars['Float']['output']>;
  title: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  vehicle: VehicleType;
  vehicleId: Scalars['String']['output'];
};

export type ReminderWhere = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  date?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ResetPasswordDto = {
  password: Scalars['String']['input'];
  resetPasswordToken: Scalars['String']['input'];
  type: Scalars['String']['input'];
};

export type ResetPasswordResponse = {
  __typename?: 'ResetPasswordResponse';
  status: Scalars['String']['output'];
};

export type ServiceEventSort = {
  createdAt?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  serviceDate?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['String']['input']>;
};

export type ServiceEventType = {
  __typename?: 'ServiceEventType';
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  isLocked: Scalars['Boolean']['output'];
  isPartnerLocked: Scalars['Boolean']['output'];
  mileage?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  partner?: Maybe<PartnerType>;
  partnerId: Scalars['String']['output'];
  photo?: Maybe<Scalars['String']['output']>;
  runningHours?: Maybe<Scalars['Int']['output']>;
  serviceDate: Scalars['DateTime']['output'];
  type: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  vehicle: VehicleType;
  vehicleId: Scalars['String']['output'];
};

export type ServiceEventWhere = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type StripeCheckoutType = {
  __typename?: 'StripeCheckoutType';
  url: Scalars['String']['output'];
};

export type TransferVehicleType = {
  __typename?: 'TransferVehicleType';
  vehicleId: Scalars['String']['output'];
};

export type UpdateBusinessDto = {
  name: Scalars['String']['input'];
};

export type UpdateFaultDto = {
  description: Scalars['String']['input'];
  isDone?: InputMaybe<Scalars['Boolean']['input']>;
  photoId?: InputMaybe<Scalars['String']['input']>;
};

export type UpdatePartnerDto = {
  name: Scalars['String']['input'];
};

export type UpdateReminderDto = {
  date: Scalars['DateTime']['input'];
  isNotificationable?: InputMaybe<Scalars['Boolean']['input']>;
  notificationDaysBefore?: InputMaybe<Scalars['Int']['input']>;
  title: Scalars['String']['input'];
};

export type UpdateServiceEventDto = {
  description?: InputMaybe<Scalars['String']['input']>;
  mileage?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  runningHours?: InputMaybe<Scalars['Int']['input']>;
  serviceDate: Scalars['DateTime']['input'];
  type: Scalars['String']['input'];
};

export type UpdateVehicleDto = {
  VIN: Scalars['String']['input'];
  brand?: InputMaybe<Scalars['String']['input']>;
  commissioning?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  mileage?: InputMaybe<Scalars['Int']['input']>;
  model?: InputMaybe<Scalars['String']['input']>;
  modelYear?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  photo?: InputMaybe<Scalars['String']['input']>;
  registerNumber?: InputMaybe<Scalars['String']['input']>;
  runningHours?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  warranty?: InputMaybe<Scalars['DateTime']['input']>;
};

export type UserList = {
  __typename?: 'UserList';
  list: Array<AdminUserType>;
  totalCount: Scalars['Int']['output'];
};

export type UserSort = {
  createdAt?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['String']['input']>;
};

export type UserType = {
  __typename?: 'UserType';
  business?: Maybe<BusinessType>;
  createdAt: Scalars['DateTime']['output'];
  email: Scalars['String']['output'];
  id: Scalars['String']['output'];
  isBusinessAccountOwner: Scalars['Boolean']['output'];
  isConfirmed: Scalars['Boolean']['output'];
  isEmployee: Scalars['Boolean']['output'];
  isPartnerAccountOwner: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  partner?: Maybe<PartnerType>;
  type: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type UserWhere = {
  businessName?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  partnerName?: InputMaybe<Scalars['String']['input']>;
  stripeCustomerId?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ValidateResetTokenDto = {
  resetPasswordToken: Scalars['String']['input'];
  type: Scalars['String']['input'];
};

export type ValidateResetTokenResponse = {
  __typename?: 'ValidateResetTokenResponse';
  status: Scalars['Boolean']['output'];
};

export type ValidateTransferTokenType = {
  __typename?: 'ValidateTransferTokenType';
  isVehicleOwner: Scalars['Boolean']['output'];
};

export type VehicleList = {
  __typename?: 'VehicleList';
  list: Array<VehicleType>;
  totalCount: Scalars['Int']['output'];
};

export type VehicleSort = {
  VIN?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['String']['input']>;
};

export type VehicleType = {
  __typename?: 'VehicleType';
  VIN: Scalars['String']['output'];
  brand?: Maybe<Scalars['String']['output']>;
  businessId?: Maybe<Scalars['String']['output']>;
  commissioning?: Maybe<Scalars['DateTime']['output']>;
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  hasActiveLicense: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  licenseExpireDate: Scalars['DateTime']['output'];
  licenseStatus: Scalars['String']['output'];
  mileage?: Maybe<Scalars['Int']['output']>;
  model?: Maybe<Scalars['String']['output']>;
  modelYear?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  photo?: Maybe<Scalars['String']['output']>;
  registerNumber?: Maybe<Scalars['String']['output']>;
  reminders: Array<ReminderType>;
  runningHours?: Maybe<Scalars['Int']['output']>;
  serviceEvents: Array<ServiceEventType>;
  token: Scalars['String']['output'];
  type?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  userId?: Maybe<Scalars['String']['output']>;
  warranty?: Maybe<Scalars['DateTime']['output']>;
};


export type VehicleTypeRemindersArgs = {
  orderBy?: InputMaybe<Array<ReminderSort>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ReminderWhere>;
};


export type VehicleTypeServiceEventsArgs = {
  orderBy?: InputMaybe<Array<ServiceEventSort>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ServiceEventWhere>;
};

export type VehicleWhere = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type VerifyAcceptInvitationDto = {
  inviteToken: Scalars['String']['input'];
};

export type VoucherDto = {
  endDate: Scalars['DateTime']['input'];
  isActive: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  useLimit: Scalars['Int']['input'];
};

export type VoucherList = {
  __typename?: 'VoucherList';
  list: Array<VoucherType>;
  totalCount: Scalars['Int']['output'];
};

export type VoucherLogType = {
  __typename?: 'VoucherLogType';
  id: Scalars['String']['output'];
};

export type VoucherSort = {
  createdAt?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['String']['input']>;
};

export type VoucherType = {
  __typename?: 'VoucherType';
  createdAt: Scalars['DateTime']['output'];
  endDate: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  isActive: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  useLimit: Scalars['Int']['output'];
  uses: Scalars['Int']['output'];
};

export type VoucherWhere = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type FindVehicleQueryVariables = Exact<{
  orderBy?: InputMaybe<Array<VehicleSort> | VehicleSort>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<VehicleWhere>;
}>;


export type FindVehicleQuery = { __typename?: 'Query', vehicles: { __typename?: 'VehicleList', list: Array<{ __typename?: 'VehicleType', id: string, registerNumber?: string | null, VIN: string }> } };

export type CreateFaultMutationVariables = Exact<{
  body: CreateFaultDto;
}>;


export type CreateFaultMutation = { __typename?: 'Mutation', createFault: { __typename?: 'FaultType', id: string } };

export type WhoAmIQueryVariables = Exact<{ [key: string]: never; }>;


export type WhoAmIQuery = { __typename?: 'Query', whoAmI: { __typename?: 'UserType', id: string, email: string, name: string, business?: { __typename?: 'BusinessType', id: string, name: string, photo?: string | null } | null } };

export type ConfirmMutationVariables = Exact<{
  body: ConfirmDto;
}>;


export type ConfirmMutation = { __typename?: 'Mutation', confirm: { __typename?: 'AuthStatusResponse', status: string } };

export type ForgotPasswordMutationVariables = Exact<{
  body: ForgotPasswordDto;
}>;


export type ForgotPasswordMutation = { __typename?: 'Mutation', forgotPasswordEmployee: { __typename?: 'ForgotPasswordResponse', status: string } };

export type LoginMutationVariables = Exact<{
  body: LoginDto;
}>;


export type LoginMutation = { __typename?: 'Mutation', loginEmployee: { __typename?: 'AuthResponse', token: string, user: { __typename?: 'UserType', id: string, name: string } } };

export type ResetPasswordMutationVariables = Exact<{
  body: ResetPasswordDto;
}>;


export type ResetPasswordMutation = { __typename?: 'Mutation', resetPassword: { __typename?: 'ResetPasswordResponse', status: string } };

export type ValidateResetTokenQueryVariables = Exact<{
  body: ValidateResetTokenDto;
}>;


export type ValidateResetTokenQuery = { __typename?: 'Query', validateResetToken: { __typename?: 'ValidateResetTokenResponse', status: boolean } };


export const FindVehicleDocument = gql`
    query FindVehicle($orderBy: [VehicleSort!], $skip: Int, $take: Int, $where: VehicleWhere) {
  vehicles(orderBy: $orderBy, skip: $skip, take: $take, where: $where) {
    list {
      id
      registerNumber
      VIN
    }
  }
}
    `;

/**
 * __useFindVehicleQuery__
 *
 * To run a query within a React component, call `useFindVehicleQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindVehicleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindVehicleQuery({
 *   variables: {
 *      orderBy: // value for 'orderBy'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useFindVehicleQuery(baseOptions?: Apollo.QueryHookOptions<FindVehicleQuery, FindVehicleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindVehicleQuery, FindVehicleQueryVariables>(FindVehicleDocument, options);
      }
export function useFindVehicleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindVehicleQuery, FindVehicleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindVehicleQuery, FindVehicleQueryVariables>(FindVehicleDocument, options);
        }
export function useFindVehicleSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<FindVehicleQuery, FindVehicleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FindVehicleQuery, FindVehicleQueryVariables>(FindVehicleDocument, options);
        }
export type FindVehicleQueryHookResult = ReturnType<typeof useFindVehicleQuery>;
export type FindVehicleLazyQueryHookResult = ReturnType<typeof useFindVehicleLazyQuery>;
export type FindVehicleSuspenseQueryHookResult = ReturnType<typeof useFindVehicleSuspenseQuery>;
export type FindVehicleQueryResult = Apollo.QueryResult<FindVehicleQuery, FindVehicleQueryVariables>;
export const CreateFaultDocument = gql`
    mutation CreateFault($body: CreateFaultDTO!) {
  createFault(body: $body) {
    id
  }
}
    `;
export type CreateFaultMutationFn = Apollo.MutationFunction<CreateFaultMutation, CreateFaultMutationVariables>;

/**
 * __useCreateFaultMutation__
 *
 * To run a mutation, you first call `useCreateFaultMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFaultMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFaultMutation, { data, loading, error }] = useCreateFaultMutation({
 *   variables: {
 *      body: // value for 'body'
 *   },
 * });
 */
export function useCreateFaultMutation(baseOptions?: Apollo.MutationHookOptions<CreateFaultMutation, CreateFaultMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateFaultMutation, CreateFaultMutationVariables>(CreateFaultDocument, options);
      }
export type CreateFaultMutationHookResult = ReturnType<typeof useCreateFaultMutation>;
export type CreateFaultMutationResult = Apollo.MutationResult<CreateFaultMutation>;
export type CreateFaultMutationOptions = Apollo.BaseMutationOptions<CreateFaultMutation, CreateFaultMutationVariables>;
export const WhoAmIDocument = gql`
    query WhoAmI {
  whoAmI {
    id
    email
    name
    business {
      id
      name
      photo
    }
  }
}
    `;

/**
 * __useWhoAmIQuery__
 *
 * To run a query within a React component, call `useWhoAmIQuery` and pass it any options that fit your needs.
 * When your component renders, `useWhoAmIQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWhoAmIQuery({
 *   variables: {
 *   },
 * });
 */
export function useWhoAmIQuery(baseOptions?: Apollo.QueryHookOptions<WhoAmIQuery, WhoAmIQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WhoAmIQuery, WhoAmIQueryVariables>(WhoAmIDocument, options);
      }
export function useWhoAmILazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WhoAmIQuery, WhoAmIQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WhoAmIQuery, WhoAmIQueryVariables>(WhoAmIDocument, options);
        }
export function useWhoAmISuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<WhoAmIQuery, WhoAmIQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<WhoAmIQuery, WhoAmIQueryVariables>(WhoAmIDocument, options);
        }
export type WhoAmIQueryHookResult = ReturnType<typeof useWhoAmIQuery>;
export type WhoAmILazyQueryHookResult = ReturnType<typeof useWhoAmILazyQuery>;
export type WhoAmISuspenseQueryHookResult = ReturnType<typeof useWhoAmISuspenseQuery>;
export type WhoAmIQueryResult = Apollo.QueryResult<WhoAmIQuery, WhoAmIQueryVariables>;
export const ConfirmDocument = gql`
    mutation Confirm($body: ConfirmDto!) {
  confirm(body: $body) {
    status
  }
}
    `;
export type ConfirmMutationFn = Apollo.MutationFunction<ConfirmMutation, ConfirmMutationVariables>;

/**
 * __useConfirmMutation__
 *
 * To run a mutation, you first call `useConfirmMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmMutation, { data, loading, error }] = useConfirmMutation({
 *   variables: {
 *      body: // value for 'body'
 *   },
 * });
 */
export function useConfirmMutation(baseOptions?: Apollo.MutationHookOptions<ConfirmMutation, ConfirmMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConfirmMutation, ConfirmMutationVariables>(ConfirmDocument, options);
      }
export type ConfirmMutationHookResult = ReturnType<typeof useConfirmMutation>;
export type ConfirmMutationResult = Apollo.MutationResult<ConfirmMutation>;
export type ConfirmMutationOptions = Apollo.BaseMutationOptions<ConfirmMutation, ConfirmMutationVariables>;
export const ForgotPasswordDocument = gql`
    mutation ForgotPassword($body: ForgotPasswordDto!) {
  forgotPasswordEmployee(body: $body) {
    status
  }
}
    `;
export type ForgotPasswordMutationFn = Apollo.MutationFunction<ForgotPasswordMutation, ForgotPasswordMutationVariables>;

/**
 * __useForgotPasswordMutation__
 *
 * To run a mutation, you first call `useForgotPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForgotPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forgotPasswordMutation, { data, loading, error }] = useForgotPasswordMutation({
 *   variables: {
 *      body: // value for 'body'
 *   },
 * });
 */
export function useForgotPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ForgotPasswordMutation, ForgotPasswordMutationVariables>(ForgotPasswordDocument, options);
      }
export type ForgotPasswordMutationHookResult = ReturnType<typeof useForgotPasswordMutation>;
export type ForgotPasswordMutationResult = Apollo.MutationResult<ForgotPasswordMutation>;
export type ForgotPasswordMutationOptions = Apollo.BaseMutationOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>;
export const LoginDocument = gql`
    mutation Login($body: LoginDto!) {
  loginEmployee(body: $body) {
    token
    user {
      id
      name
    }
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      body: // value for 'body'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation ResetPassword($body: ResetPasswordDto!) {
  resetPassword(body: $body) {
    status
  }
}
    `;
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      body: // value for 'body'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, options);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const ValidateResetTokenDocument = gql`
    query ValidateResetToken($body: ValidateResetTokenDto!) {
  validateResetToken(body: $body) {
    status
  }
}
    `;

/**
 * __useValidateResetTokenQuery__
 *
 * To run a query within a React component, call `useValidateResetTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidateResetTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidateResetTokenQuery({
 *   variables: {
 *      body: // value for 'body'
 *   },
 * });
 */
export function useValidateResetTokenQuery(baseOptions: Apollo.QueryHookOptions<ValidateResetTokenQuery, ValidateResetTokenQueryVariables> & ({ variables: ValidateResetTokenQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ValidateResetTokenQuery, ValidateResetTokenQueryVariables>(ValidateResetTokenDocument, options);
      }
export function useValidateResetTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ValidateResetTokenQuery, ValidateResetTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ValidateResetTokenQuery, ValidateResetTokenQueryVariables>(ValidateResetTokenDocument, options);
        }
export function useValidateResetTokenSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ValidateResetTokenQuery, ValidateResetTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ValidateResetTokenQuery, ValidateResetTokenQueryVariables>(ValidateResetTokenDocument, options);
        }
export type ValidateResetTokenQueryHookResult = ReturnType<typeof useValidateResetTokenQuery>;
export type ValidateResetTokenLazyQueryHookResult = ReturnType<typeof useValidateResetTokenLazyQuery>;
export type ValidateResetTokenSuspenseQueryHookResult = ReturnType<typeof useValidateResetTokenSuspenseQuery>;
export type ValidateResetTokenQueryResult = Apollo.QueryResult<ValidateResetTokenQuery, ValidateResetTokenQueryVariables>;